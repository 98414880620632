.sidebar {
	position: fixed;
	width: @sideBarWidth;
	height: 100%;
	top: @keretMagassag;
	margin-top: 0 - @keretMagassag;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	overflow: hidden;
	z-index: 800;

	&.left {
		left: 0 - @sideBarWidth;
		background-image: url("@{images-url}bg_side_left.jpg");
	}

	&.right {
		right: 0 - @sideBarWidth;
		background-image: url("@{images-url}bg_side_right.jpg");
	}

	.wrapper {
		margin: @keretMagassag 0;
		position: relative;
	}

	.content{
		//position: absolute;
		//top: 50%;
		//margin-top: -60%;
		padding: 0 75px;

		.submenu {
			padding-top: 24px;
		}

		.cim {
			margin: .3em 0;
		}
	}

	dt {
		font-size: @baseFontSize * 1.2;
	}
	dd {
		margin: 0 0 1em 0;
	}
}

#terkep {
	.content {
		position: static;
		top: auto;
		margin-top: auto;
	}

	.bezar {
		top: 40px;
	}

	.menu {
		position: absolute;
		top: 40px;
		left: 52px;
		background: @majdnemFeher;
		z-index: 2000;
		padding: .5em 1em;
	}

	#GoogleMap {
		width: 100%;
		height: 100%;
		//margin-top: @keretMagassag * 2;
		//.box-sizing(border-box);
	}
}