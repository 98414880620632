#sikerek {
	background-image: url("@{images-url}bg_sikerek.jpg");

	.container_wrapper {
		position: relative;

	}
	.uveg {
		position: absolute;
		left: 340px;
		top: -80px;
	}

	.dropdown-toggle {
		color: @betuSzin;
	}

	.evvalaszto {
		margin-top: -19em;
		position: relative;
	}

	.sikerekcim {
		color: @menuBetuSzin;
		font-size: @baseFontSize * 2.4;
		padding-left: 540px;
	}

	.submenu {
		li {
			float: none;

			&:before {
				content: '';
			}

			a {
				font-size: @baseFontSize * 1.8;
				line-height: @baseFontSize * 3;

				&.active {
					color: @betuSzin;
				}
			}
		}
	}

	article {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		width: 450px;

		.cim {
			line-height: @baseFontSize * 6.6;
		}

		&.active {
			display: block;
		}

		.dij {
			display: block;
			position: absolute;
			left: -370px;
			top: 310px;
		}

		.cimke {
			display: block;
			position: absolute;
			left: -193px;
			top: 200px;
			width: @termekekUvegWidth - 12;

			&.aszu {
				top: 345px;
			}
		}
	}
}