#galeria {
	background: url("@{images-url}bg_galeria.jpg") right center no-repeat;

	.items {
		height: 100% !important;
		overflow: hidden;
	}

	.sor {
		height: 33.4%;
		clear: both;
	}

	.item {
		float: left;
		width: auto;

		img {
			display: none;
			height: 100%;
		}
	}
}