// COMMON STYLES
// -------------

// Clear any floats
.tabbable {
  .clearfix();
}
.tab-content {
  overflow: visible; // prevent content from running below tabs
}

// Remove border on bottom, left, right
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}

// Show/hide tabbable areas
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}
.tab-content > .active,
.pill-content > .active {
  display: block;
}

.nav-tabs {
	display: table;
}
