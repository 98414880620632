#termekek {
	background-image: url("@{images-url}bg_termekek.jpg");

	.slide {
		img {
			display: none;
		}

		p {
			width: 630px;
		}
	}

	.slidesjs-navigation {
		width: 30px;
		height: 30px;
		overflow: hidden;
		display: block;
		float: left;
	}

	.slidesjs-previous {
		background: @slidernavBal;

		&:hover {
			background: @slidernavBalHover;
		}
	}

	.slidesjs-next {
		background: @slidernavJobb;

		&:hover {
			background: @slidernavJobbHover;
		}
	}
}