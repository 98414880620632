//
// Mixins
// --------------------------------------------------


// Box sizing
.box-sizing(@boxmodel) {
  -webkit-box-sizing: @boxmodel;
     -moz-box-sizing: @boxmodel;
          box-sizing: @boxmodel;
}

// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
.clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}


// Border Radius
.border-radius(@radius) {
  -webkit-border-radius: @radius;
     -moz-border-radius: @radius;
          border-radius: @radius;
}

// Single Corner Border Radius
.border-top-left-radius(@radius) {
  -webkit-border-top-left-radius: @radius;
      -moz-border-radius-topleft: @radius;
          border-top-left-radius: @radius;
}
.border-top-right-radius(@radius) {
  -webkit-border-top-right-radius: @radius;
      -moz-border-radius-topright: @radius;
          border-top-right-radius: @radius;
}
.border-bottom-right-radius(@radius) {
  -webkit-border-bottom-right-radius: @radius;
      -moz-border-radius-bottomright: @radius;
          border-bottom-right-radius: @radius;
}
.border-bottom-left-radius(@radius) {
  -webkit-border-bottom-left-radius: @radius;
      -moz-border-radius-bottomleft: @radius;
          border-bottom-left-radius: @radius;
}

// Single Side Border Radius
.border-top-radius(@radius) {
  .border-top-right-radius(@radius);
  .border-top-left-radius(@radius);
}
.border-right-radius(@radius) {
  .border-top-right-radius(@radius);
  .border-bottom-right-radius(@radius);
}
.border-bottom-radius(@radius) {
  .border-bottom-right-radius(@radius);
  .border-bottom-left-radius(@radius);
}
.border-left-radius(@radius) {
  .border-top-left-radius(@radius);
  .border-bottom-left-radius(@radius);
}

// Drop shadows
.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow;
     -moz-box-shadow: @shadow;
          box-shadow: @shadow;
}

// Transitions
.transition(@transition) {
  -webkit-transition: @transition;
     -moz-transition: @transition;
       -o-transition: @transition;
          transition: @transition;
}
.transition-delay(@transition-delay) {
  -webkit-transition-delay: @transition-delay;
     -moz-transition-delay: @transition-delay;
       -o-transition-delay: @transition-delay;
          transition-delay: @transition-delay;
}
.transition-duration(@transition-duration) {
  -webkit-transition-duration: @transition-duration;
     -moz-transition-duration: @transition-duration;
       -o-transition-duration: @transition-duration;
          transition-duration: @transition-duration;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden
// See git pull https://github.com/dannykeane/bootstrap.git backface-visibility for examples
.backface-visibility(@visibility){
	-webkit-backface-visibility: @visibility;
	   -moz-backface-visibility: @visibility;
	        backface-visibility: @visibility;
}

// Background clipping
// Heads up: FF 3.6 and under need "padding" instead of "padding-box"
.background-clip(@clip) {
  -webkit-background-clip: @clip;
     -moz-background-clip: @clip;
          background-clip: @clip;
}

// Background sizing
.background-size(@size) {
  -webkit-background-size: @size;
     -moz-background-size: @size;
       -o-background-size: @size;
          background-size: @size;
}

// Opacity
.opacity(@opacity) {
  opacity: @opacity / 100;
  filter: ~"alpha(opacity=@{opacity})";
}

// disable text selection
.userselect(@value) {
	-webkit-touch-callout: @value;
	-webkit-user-select: @value;
	-khtml-user-select: @value;
	-moz-user-select: @value;
	-ms-user-select: @value;
	user-select: @value;
}