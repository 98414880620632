#fooldal {
	background-image: url("@{images-url}bg_fooldal.jpg");

	.logo {
		margin: -187px 0 0 -215px;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 430px;
		height: 375px;
	}
}