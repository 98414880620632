.sbHolder {
	padding: 0;
	position: relative;
	display: inline-block;
	width: 100%;
	margin: .6em 0;

	a,
	a:hover {
		color: #121212;
		text-decoration: none;
	}

	&:focus {
		outline: 0 none;
	}

	ul {
		list-style: outside none;
		margin: 0;
		padding: 0;
	}

	.sbToggle {
		background: @selectNyit 50% 50% no-repeat;
		position: absolute;
		right: 0;
		top: 0;
		width: 46px;
		height: 100%;
		overflow: hidden;
		-webkit-border-top-right-radius: 4px;
		-webkit-border-bottom-right-radius: 4px;
		-moz-border-radius-topright: 4px;
		-moz-border-radius-bottomright: 4px;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;

		&.sbToggleOpen {
			background-image: @selectCsuk;
		}

	}

	.sbSelector {
		display: block;
		border: 1px solid @vilagosSzurke;
		margin: 0;
	}

	.sbOptions {
		box-shadow: 0 3px 5px #808080;
		left: 0;
		position: absolute;
		top: 100% !important;
		width: 100%;
		z-index: 5000;

		li {
			background: #fff;
			border-top: 1px solid @vilagosSzurke;

			a {
				display: block;
				padding: .5em 1em;

				&:hover {
					background-color: @szurke;
					color: @white;
				}
			}
		}
	}
}