#borkut {
	background-image: url("@{images-url}bg_termekek.jpg");

	.slide {
		.kep {
			display: inline-block;

			+.kep {
				margin-left: 10px;
			}

			img {
				max-width: 100%;
				max-height: 285px;
			}
		}

		p {
			width: 630px;
			font-size: 16px;
		}
	}

	.cim {
		margin-top: 0;
		margin-bottom: .3em;
	}

	.slidesjs-navigation {
		width: 30px;
		height: 30px;
		overflow: hidden;
		display: block;
		float: left;
	}

	.slidesjs-previous {
		background: @slidernavBal;

		&:hover {
			background: @slidernavBalHover;
		}
	}

	.slidesjs-next {
		background: @slidernavJobb;

		&:hover {
			background: @slidernavJobbHover;
		}
	}
}