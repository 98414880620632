#cimketerv {
	background-image: url("@{images-url}bg_cimketerv.jpg");

	.keret {
		margin-top: -19em;
		position: relative;

		.cim {
			margin-bottom: 0;
		}
	}

	.bal {
		width: 320px;
	}

	.submenu {
		margin-bottom: 1em;
		li {
			float: none;

			&:before {
				content: '';
			}

			a {
				font-size: @baseFontSize * 1.8;
				line-height: @baseFontSize * 3;

				&.active {
					color: @betuSzin;
				}
			}
		}
	}

	input[type=text],
	input[type=password],
	input[type=email],
	textarea {
		border: 1px solid @vilagosSzurke;
	}

	.fileinfo {
		float: left;
		font-size: @baseFontSize * 1.1;
		line-height: 1.4em;
		margin-left: .5em;
	}

	.filevalue {
		font-size: @baseFontSize * 1.1;
		margin: .5em 0;
	}


	.cimkezescim {
		color: @menuBetuSzin;
		font-size: @baseFontSize * 2.4;
	}

	.adatok {
		position: absolute;
		top: 0;
		right: 0;
		width: 400px;

		.cim {
			line-height: @baseFontSize * 6.6;
		}

		&.active {
			display: block;
		}

		input[type=submit] {
			margin-left: 1em;
		}

		.help {
			color: @menuBetuSzin;
			font-size: @baseFontSize * 1.1;
		}
	}
}

#cimketervezo {
	#cimkemenu {
		margin-bottom: @modalPadding;

		li {
			float: left;

			a {
				font-family: @serifFont;
				font-size: @baseFontSize * 1.8;
				line-height: @baseFontSize * 3;
			}

			&.active a {
				color: @black;
				font-size: @baseFontSize * 2.6;
				line-height: @baseFontSize * 3;
			}

			&:before {
				content: '>';
				color: @menuBetuSzin;
				margin: 0 10px;
				display: inline-block;
				font-size: @baseFontSize * 1.4;
				line-height: @baseFontSize * 3;
			}

			&:first-child:before {
				content: '';
				margin: 0;
			}
		}

		&.reg li:before {
			content: '';
		}
	}

	.tab-content {
		.sablon {
			max-width: 200px;
			max-height: 200px;
			margin: 0 15px 15px 0;
		}
	}

	.szerkeszto {
		width: 620px;
		height: 480px;
		border: 1px solid @vilagosSzurke;
		.box-sizing(border-box);
		margin-top: 8px;
	}
	.kineticjs-content {
		width: 263px;
		margin: 0px auto;
		display: block !important;
	}

	.szerkeszto img {
		margin: 0px auto;
		display: block;
	}

	.szerkeszto_control {
		width: @kepszerkesztoControlsWidth;
		.box-sizing(border-box);
		padding-left: @modalPadding;
		float: left;

		input[type=button], button {
			width: 100%;
			.box-sizing(border-box);
			margin-bottom: @modalPadding;
		}
	}

	.tovabb {
		position: absolute;
		right: @modalPadding;
		bottom: @modalPadding;
	}

	.vissza {
		position: absolute;
		left: @modalPadding;
		bottom: @modalPadding;
	}

	.kijelentkezes {
		position: absolute;
		bottom: @modalPadding;
		height: 32px;
		text-align: center;
		width: 60%;
		margin:0px 20%;
		line-height: 32px;
	}

	.bezar {
		right: @modalPadding;
		top: @modalPadding;
	}

	* + .fele,
	* + .harmada,
	* + .negyede {
		.box-sizing(border-box);
		padding-left: 20px;
	}
}