// TápióVin master css
// recess --compile ./less/master.less > ./css/master.css

@import "variables.less";
@import "mixins.less";
@import "alap.less";

@import "layout.less";
@import "menu.less";
@import "sidebar.less";
@import "tabs.less";
@import "dropdowns.less";
@import "selectbox.less";
@import "alerts.less";
@import "modals.less";

@import "fooldal.less";
@import "termekek.less";
@import "borkut.less";
@import "sikerek.less";
@import "cimketerv.less";
@import "galeria.less";
@import "hirek";

#hirek {
	.content {
		height: 100%;
		overflow: hidden;
	}
}

.hir-wrapper {
	height: ~"calc(100% - 200px)";
	overflow-y: scroll;
	overflow-x: hidden;
	padding-right: .5em;
}

.hir-cim {
	margin: 0 auto .5em;
	font-size: 1.6em;
	font-weight: bold;
}