
header {
	.menu_controller {
		background-color: @szurke;
		color: @white;
		cursor: default;
		display: block;
		font-size: @menuFontSize;
		line-height: @menuControllerHeight;
		height: @menuControllerHeight;
		margin: 0 0 0 @menuControllerWidth / -2;
		top: 0;
		left: 50%;
		position: absolute;
		text-align: center;
		width: @menuControllerWidth;
	}

	.lang_select {
		position: absolute;
		z-index: 1000;
		top: 0;
		left: 50%;
		width: @menuControllerWidth;
		margin: 0 0 0 @menuControllerWidth / -2;
		line-height: 50px;
		height: 50px;
		text-align: center;
		display: none;

		a {
			font-family: @serifFont;
			font-size: @baseFontSize * 1.8;
			display: inline-block;
			margin: 0 5px;

			&:hover, &.aktiv {
				color: @white;
			}
		}
	}

	nav {
		display: none;
	}

	.menu_left, .menu_right {
		width: 50%;
		height: @menuMagassagNyitva;
		float: left;
		background-color: @keretSzurke;

		a {
			& {
				color: @menuBetuSzin;
				font-family: @serifFont;
				font-size: @menuFontSize;
				height: @menuMagassagNyitva;
				line-height: @menuMagassagNyitva;
				padding: 0 12px;
				text-decoration: none;
				display: inline-block;
			}

			&:hover {
				color: @white;
			}
		}
	}

	.menu_left a {
		float: right;

		&:first-child {
			margin-right: @menuControllerWidth / 2;
		}
	}

	.menu_right a {
		float: left;

		&:first-child {
			margin-left: @menuControllerWidth / 2;
		}
	}
}