// --------------------------------------------------
// Variables
// --------------------------------------------------

@base-url:				"/";
@images-url:			"@{base-url}media/images/";

// színek

@white:					#fff;
@majdnemFeher:			#f5f5f5;
@vilagosSzurke:			#d3d3d3;
@menuBetuSzin:			#9c9c9c;
@szurke:				#565656;
@betuSzin:				#434343;
@keretSzurke:			#232421;
@black:					#000;

// fontok

@serifFont:				Garamond, "Times New Roman", Times, serif;
@sansSerifFont:			Tahoma, Helvetica, sans-serif;

@baseFontSize:			10px; //azért 10px, hogy egyszerűbben lehessen számolni
@paragraphFontSize:		@baseFontSize * 1.4;
@menuFontSize:			@baseFontSize * 2;

@baseLineHeight:		1.4em;

// méretek

@keretMagassag:			50px;
@menuControllerWidth:	116px;
@menuControllerHeight:	60px;
@menuMagassagNyitva:	@keretMagassag * 2;
@termekekUvegWidth:		167px;

@sideBarWidth:			554px;
@modalWidth:			810px;
@modalHeight:			600px;
@modalPadding:			20px;
@kepszerkesztoControlsWidth: 190px;


// képek

@inputBg:				url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpGMDIxREJERUMzNkZFMjExQUFGRkQ0M0UxRkYwOTQzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoxMTdGQTk4MjhDQTQxMUUyQkVBQkU3MDRFQzQ5NEY4NCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoxMTdGQTk4MThDQTQxMUUyQkVBQkU3MDRFQzQ5NEY4NCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IFdpbmRvd3MiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5QjVCRDA4RTkzNzRFMjExOTMxQzg5RERBNTAzMEIxQSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGMDIxREJERUMzNkZFMjExQUFGRkQ0M0UxRkYwOTQzQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuEAi5YAAAAlSURBVHjaYvz//38aAxUBEwOVwaiBowaOGjhq4KiBQ8VAgAADAO0JA4vpLTf2AAAAAElFTkSuQmCC");
@slidernavBal:			url("data:image/gif;base64,R0lGODlhHgAeAMQAAFZWVnFxcXBwcG9vb/T09HZ2dpaWlr+/v3JycnBvb////2FhYd/f36CgoGxsbIWFhWlpaXNzc21tbXNycouLi3BvcKqqqm9wcIiIiGloaNXV1WhoaISEhAAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjAgNjEuMTM0Nzc3LCAyMDEwLzAyLzEyLTE3OjMyOjAwICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOkYwMjFEQkRFQzM2RkUyMTFBQUZGRDQzRTFGRjA5NDNCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjJFODU4MUI4OTA3NDExRTI5MTc3RTcxM0YzOEEzNTkyIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjJFODU4MUI3OTA3NDExRTI5MTc3RTcxM0YzOEEzNTkyIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzUgV2luZG93cyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjRFRDVFQ0YxQjM4RkUyMTFBRTQ5OTRCMTM1RDFFREIyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkYwMjFEQkRFQzM2RkUyMTFBQUZGRDQzRTFGRjA5NDNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAAAAAAAsAAAAAB4AHgAABaHg4yTCYJ5CMEURggSwIM9C4jwZoO987/+QxG9I5AWExaQvMFA6d8znM3CR6gxFmdWgyDafBgLF+2wQCkmt8nBWptZtZQCRPDAWU/pwoblLYUQWfn8BRGZohEVsiE6AigpYjYVJXJFJAQJOXA1umZoEB2meTgWgWZNPpadWAIw/b6yrsURqsz8Do7Y9CRC6PxsYEjDDJSkumDLEMicDFQ4cIQA7");
@slidernavBalHover:		url("data:image/gif;base64,R0lGODlhHgAeAMQAAIsGBpsuLpwvL50wMPjv76E1NbdkZNOhoZ4xMf///5wuLpIWFunQ0L5zc5cjI6lPT5koKJsuL6hPT6xTU5goKK9UVJopKZwwL5ckJJ0vL5stLsWCguLBwZsuLQAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjAgNjEuMTM0Nzc3LCAyMDEwLzAyLzEyLTE3OjMyOjAwICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOkYwMjFEQkRFQzM2RkUyMTFBQUZGRDQzRTFGRjA5NDNCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjUzOUUzNDY1OTA3NDExRTJBQjI1OEFFNkUyOUEwNUNGIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjUzOUUzNDY0OTA3NDExRTJBQjI1OEFFNkUyOUEwNUNGIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzUgV2luZG93cyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjRFRDVFQ0YxQjM4RkUyMTFBRTQ5OTRCMTM1RDFFREIyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkYwMjFEQkRFQzM2RkUyMTFBQUZGRDQzRTFGRjA5NDNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAAAAAAAsAAAAAB4AHgAABZ7gAwVkoHVkNCAsMgiwQAqKHECPA+x87/9ARwBILPZkxiQQqWzumE4l1GkwTpWGhFExpBIqRpKzQSgkxcpDWTpIr9nJA2PhFCCKC848KmgTN3t8AkVkZlEvRmqGTYiJCVWMg0lZkElXRFkNSmhYBAdnkk0FnmGhogRbflGLSxdRTQGmr0Sxs6C2YRi4RQ4TFjEwJX0DGVyxLyXJARQSIQA7");
@slidernavJobb:			url("data:image/gif;base64,R0lGODlhHgAeAMQAAFZWVnBwcG9vb3FxcfT09HZ2dpaWlr+/v3Bvb2FhYf///2hoaGxsbKCgoN/f34aGhtXV1aqqqnFxcHBxcXFwcIuLi4SEhIODg2lpaWtrawAAAAAAAAAAAAAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjAgNjEuMTM0Nzc3LCAyMDEwLzAyLzEyLTE3OjMyOjAwICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOkYwMjFEQkRFQzM2RkUyMTFBQUZGRDQzRTFGRjA5NDNCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjJFODU4MUJDOTA3NDExRTI5MTc3RTcxM0YzOEEzNTkyIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjJFODU4MUJCOTA3NDExRTI5MTc3RTcxM0YzOEEzNTkyIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzUgV2luZG93cyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjRFRDVFQ0YxQjM4RkUyMTFBRTQ5OTRCMTM1RDFFREIyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkYwMjFEQkRFQzM2RkUyMTFBQUZGRDQzRTFGRjA5NDNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAAAAAAAsAAAAAB4AHgAABZ3gwwRCGZClea5sMAxUkF0YYN94ru/LsP9AnMAXLOqGxuQNQVQaBYGgwWmDBhVTpxVYIWSTW2CB0FCGxYQDOGocq4vIZMLxBsblDkjC3sRH+FRjZT8vTm5BAxNKBgp1hGxFjF9AAxJGDVhJhUEHXkqbP50FTidBBKOkkDuoWqpUP2evOyWyQQIItXYLuT8LFgy3Jym3CC0rAywpDA8hADs=");
@slidernavJobbHover:	url("data:image/gif;base64,R0lGODlhHgAeAMQAAIsGBpsuLpwvL/jv76E1NbdkZNOhoZ0wMP///5IWFr5zc+nQ0JcjI5otLZkoKJgoKKdOTqhPT+LBwZgnJ69UVJwvLqpQUMWCgpYiIqlQUAAAAAAAAAAAAAAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjAgNjEuMTM0Nzc3LCAyMDEwLzAyLzEyLTE3OjMyOjAwICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOkYwMjFEQkRFQzM2RkUyMTFBQUZGRDQzRTFGRjA5NDNCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjUzOUUzNDY5OTA3NDExRTJBQjI1OEFFNkUyOUEwNUNGIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjUzOUUzNDY4OTA3NDExRTJBQjI1OEFFNkUyOUEwNUNGIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzUgV2luZG93cyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjRFRDVFQ0YxQjM4RkUyMTFBRTQ5OTRCMTM1RDFFREIyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkYwMjFEQkRFQzM2RkUyMTFBQUZGRDQzRTFGRjA5NDNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAAAAAAAsAAAAAB4AHgAABZdg5gRkaZKCKaToGkwQA8x0bd84JuB8XwcHnxAHHBppgd3RmPQVljOSD/FcSnuUQZUZEBIGiuPV9zVwjeVh05hYmH1r9kKS6BWX7Ys9uPyGeQJ8aANvPAdKQwUIhYAVRopbPStDClRGkz4GWkeYPJoES504A6BWiDilVl1QQmOsPK6vN7GyPzK1OAwRDycNDSouLcElDhYhADs=");
@dropdownCaret:			url("data:image/gif;base64,R0lGODlhHgAeAMQAAFZWVltbW2FhYd/e3nZ2dvTz87+/v1paWv/+/mdnZ4CAgFhYWNXU1IuLi5aVlWtra+rp6aCgoLW0tAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjAgNjEuMTM0Nzc3LCAyMDEwLzAyLzEyLTE3OjMyOjAwICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOkYwMjFEQkRFQzM2RkUyMTFBQUZGRDQzRTFGRjA5NDNCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjcxQjQzM0I0OTA5MzExRTJCRDZGRDdFNEMyMUZFN0ZEIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjcxQjQzM0IzOTA5MzExRTJCRDZGRDdFNEMyMUZFN0ZEIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzUgV2luZG93cyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjRFRDVFQ0YxQjM4RkUyMTFBRTQ5OTRCMTM1RDFFREIyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkYwMjFEQkRFQzM2RkUyMTFBQUZGRDQzRTFGRjA5NDNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAAAAAAAsAAAAAB4AHgAABZHgEYxkaZ7mkSRA675wLC+LbN/viO+yzv8tH5AnHOKKRhsyGVsyc4GYgvB7KATQmAPR2AkGBphTUqDeGINmVMaAYGUGt9r2Zcgc5d5aRiiEXwpcSnsyDQgOLn0RN04vEQhUX3+DPAYFDwN2R4R0AwUDb4ycdAoPRKNPLo2pq0+tTK9JsUazQ7VANCIou7wBKgkhADs=");
@selectNyit:			url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAIAAAC0Ujn1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpGMDIxREJERUMzNkZFMjExQUFGRkQ0M0UxRkYwOTQzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpGRDQzRUVGMzkxNTAxMUUyQjZEQzk4QkFDMDk0Mzc3RiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpGRDQzRUVGMjkxNTAxMUUyQjZEQzk4QkFDMDk0Mzc3RiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IFdpbmRvd3MiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBRDNFMUNBNUE3OTBFMjExQkExOUQ1RjMxNzMzQ0Y0OSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGMDIxREJERUMzNkZFMjExQUFGRkQ0M0UxRkYwOTQzQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuPN+AsAAAEpSURBVHja3JZbC4JAEIV1ERHbt+79///la/dARERE+2oioja3aDeoeRDbds7OnDkza5hlWRiGgTvruk4pxUskPwKn1rbtBdq5CQ1eoIUGj1GrwJv9IPRJgp6godujQgxRJ0kSx/GLKFEUsV/a7zZkTBl3L+bzNE3thVJqMh7rwUDaz17GoijyPB8Nh9bYweW53e3eUMj+cKjrGs+7TG+Ns8lvuVqZyO4V33qzIU2J69G01jAme0z66IXGh0zhhOge68wi/5LZM4lYWgZP/PXZroscRiowVpZlj6rt3Yg/KNeSQj3vLFJnB40OCpqZTacUjacQ5WyGEHjTNIhdyutyXhMpIoMTDjBL4pNbBsSqqv59XvuF/s0b/bu3jEuunX1OdpehJ1P+KMAAlS+Qh2EBRwIAAAAASUVORK5CYII=");
@selectCsuk:			url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAIAAAC0Ujn1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpGMDIxREJERUMzNkZFMjExQUFGRkQ0M0UxRkYwOTQzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyOUE3Q0RCODkxNTExMUUyOTYyMkUyRjA1MjMzNTFENSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyOUE3Q0RCNzkxNTExMUUyOTYyMkUyRjA1MjMzNTFENSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IFdpbmRvd3MiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBRDNFMUNBNUE3OTBFMjExQkExOUQ1RjMxNzMzQ0Y0OSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGMDIxREJERUMzNkZFMjExQUFGRkQ0M0UxRkYwOTQzQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuJpWE8AAAEXSURBVHja7JZfC4IwFMW3MUREBLOy7//NfMnMfBBURIZ28oJB/mHWfMoLSs7t19m59zJ5FEWMsbZtOefMXHRdJwEdHpjRELwPtkEItlns6H9DSylt2xZCd4nUbBYQz6cT6EqpW5IMjWZANbi4X+MY9IPvaxmiI/kYBFANsSTZdV3P8ww0OiiO4zyyjExomga/IRyDPxmC9aDc0xTEYbDoA1uxLOtLNFZiPTTWdf3xCoP4MzJqNZpKoqoqCJycgK3QnNXoSxgiaVA3NwHWg46dzRWMmCsJ0rWcCUop8oyamW6ZsRVFWao81+kLOIZin3RcTu50nLdl7bOGGD8V3+j9bNzRC6fMRpX3QlMjmfmc7PqLc5L7FGAAUymRWypQfr4AAAAASUVORK5CYII=");
@checkbox:				url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAIAAAC0Ujn1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpGMDIxREJERUMzNkZFMjExQUFGRkQ0M0UxRkYwOTQzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyNkY1M0U2QUIwQTkxMUUyQjcwRkMzNkUyMDU4QjcxMyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyNkY1M0U2OUIwQTkxMUUyQjcwRkMzNkUyMDU4QjcxMyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IFdpbmRvd3MiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3RjVEQTk0QjI2QThFMjExQTQ1QjgyMzQ1NzU5NjRDOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGMDIxREJERUMzNkZFMjExQUFGRkQ0M0UxRkYwOTQzQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PljMpE0AAABYSURBVHja7NahEQAxCAVRLoNIFQgKpkQEkgoiEJe5GmDO/C3g6X3MLCKYmfqqKhFhd89M6u7qa+9NA1120VigQYMGDRo0aNC/0eecCfeyrKr81b6TrwADAI3PGgKjdYTGAAAAAElFTkSuQmCC");
@checkboxChecked:		url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAIAAAC0Ujn1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpGMDIxREJERUMzNkZFMjExQUFGRkQ0M0UxRkYwOTQzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDozOENBMkJGMUIwQTkxMUUyQUQwRjlEOTE3MUY2MkZEQSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDozOENBMkJGMEIwQTkxMUUyQUQwRjlEOTE3MUY2MkZEQSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IFdpbmRvd3MiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3RjVEQTk0QjI2QThFMjExQTQ1QjgyMzQ1NzU5NjRDOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGMDIxREJERUMzNkZFMjExQUFGRkQ0M0UxRkYwOTQzQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjPEqPsAAAE0SURBVHjaYtkYEfHmyhVmdnYG6oG/P3+K6OiwvDx37uODBwzUBn9//WJi4+VloAEAGsvEQDMwavQIN9qpr8+qpgaPAhayzTXKzoZkjZNdXVjVMAdISX179Yo8c4FA3snp358/T44cQVPDLS7ORLZ74eDMhAkkh7Wco6NecjIec////TuBn//Pjx+khbW0tbX3ggXcEhIsnJznpkzBau5EISFc5uI0WsHFxWP2bKC5QLYjOJZ4ZWRIMhen0U+OHuWRkoKwGZmZnXp7kWX///tH0FycYf3n+/eJgoLYdfz/D5QiaC6+aPz97RsW0///nyAgQIy5BFIIuumkmEs4o8NNB+aLftzpjMyMDjH939+/wMqUpMxFVBkCNH20vB6xRv/6/JkW5gKNZRE3MmJmY6NFcxIgwADg4pRDtimXiwAAAABJRU5ErkJggg==");