html, body {
	background: @majdnemFeher;
	height: 100%;
}

body {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	overflow: hidden;
}

html, button, input, select, textarea {
	color: @betuSzin;
	font-size: @baseFontSize * 1.4;
	font-family: @sansSerifFont;
	line-height: @baseLineHeight;
}

section {
	bottom: 0;
	float: left;
	display: block;
	overflow: hidden;
	position: relative;
	margin: @keretMagassag 0;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

header, footer {
	width: 100%;
	height: @keretMagassag;
	background: @keretSzurke;
	color: @white;
	position: fixed;
	left: 0;
	z-index: 1000;
}

header {
	top: 0;
}

footer {
	bottom: 0;
}

h1,h2,h3,h4,h5,h6 {
	font-family: @serifFont;
	font-weight: normal;
}

p {
	text-align: justify;
}

a, a:hover, a:active, a:visited {
	text-decoration: none;
	color: @menuBetuSzin;
}

:focus, :active {
	outline: 0 none;
}

ul, li {
	list-style: outside none;
	margin: 0;
	padding: 0;
}

.container_wrapper {
	display: table;
	width: 990px;
	height: 100%;
	margin: 0 auto;

	.container {
		display: table-cell;
		vertical-align: middle;
		position: relative;
	}
}

.egesz {
	width: 100%;
}

.fele {
	width: 50%;
}

.harmada {
	width: 33%;
}

.negyede {
	width: 25%;
}

.submenu {
	li {
		float: left;
	}

	li, a {
		font-size: @baseFontSize * 2.4;
		font-family: @serifFont;

		&:before {
			content: "\00a0\00b7\00a0";
		}

		&:first-child:before {
			content: "";
		}

		&.aktiv, &.active a {
			color: @betuSzin;
		}
	}
}

.cim {
	font-size: @baseFontSize * 6;
	font-family: @serifFont;
}

input, textarea, .sbSelector {
	display: block;
	width: 100%;
	padding: .9em;
	margin: .6em 0;
	.box-sizing(border-box);
	border: 0 none;
	background: transparent @inputBg;
}

textarea {
	height: 11em;
}

input[type=button],
input[type=submit],
input[type=reset],
button {
	background-color: @szurke;
	background-image: none;
	border: 0 none;
	color: @white;
	font-size: @baseFontSize * 1.2;
	padding: 8px 12px;
	margin: 0;
	width: auto;
}

.captcha {
	input[type=text] {
		width: 100px;
		margin: 0 0 8px 8px;
	}

	img {
		margin: 0 8px 8px 0;
	}
}

input[type=checkbox] {
	display: none;
	width: auto;

	& + label {
		background: @checkbox 0 0 no-repeat;
		color: @betuSzin;
		cursor: default;
		display: block;
		padding: 4px 0 0 40px;
		line-height: @baseLineHeight;
		min-height: 30px;
		position: static;
		text-indent: 0;
		.userselect(none);
	}

	&:checked + label {
		background-image: @checkboxChecked;
	}
}

.uveg {
	float: left;
	margin-right: 45px;
	background-repeat: no-repeat;
	background-position: 0 0;
	width: @termekekUvegWidth;
	height: 666px;
	position: relative;
	padding-top: 260px;
	.box-sizing(border-box);

	&.feher {
		background-image: url("@{images-url}uveg_feher.png");
	}

	&.rose {
		background-image: url("@{images-url}uveg_rose.png");

		img {
			width: @termekekUvegWidth - 9;
		}
	}

	&.voros {
		background-image: url("@{images-url}uveg_voros.png");
	}

	&.voros_bordo {
		background-image: url("@{images-url}uveg_voros_bordo.png");
	}

	&.voros_kek {
		background-image: url("@{images-url}uveg_voros_kek.png");
	}

	&.aszu {
		background-image: url("@{images-url}uveg_aszu.png");
		padding-top: 423px;
	}

	img {
		display: block;
		width: @termekekUvegWidth - 7;
		margin: 0 auto;
	}
}

.bezar {
	position: absolute;
	width: 30px;
	height: 30px;
	color: @majdnemFeher;
	cursor: pointer;
	font: 18px/30px normal @serifFont;
	text-align: center;
	background: @szurke;
	top: 25px;
	right: 20px;
	z-index: 2000;

	&:before {
		content: 'X';
	}
}