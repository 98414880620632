//
// Dropdown menus
// --------------------------------------------------


// Use the .menu class on any <li> element within the topbar or ul.tabs and you'll get some superfancy dropdowns
.dropup,
.dropdown {
  //position: relative;
}
.dropdown-toggle {
  // The caret makes the toggle a bit too tall in IE7
  *margin-bottom: -3px;
}
.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}

// Dropdown arrow/caret
// --------------------
.caret {
  display: inline-block;
  width: 30px;
  height: 30px;
  overflow: hidden;
  vertical-align: top;
  background: @dropdownCaret;
  content: "";
}

// Place the caret
.dropdown .caret {
  margin-top: 27px;
  margin-left: 12px;
}

// The dropdown menu (ul)
// ----------------------
.dropdown-menu {
  position: absolute;
  top: @baseFontSize * 12;
  left: 0;
  z-index: 1000;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  padding: 0;
  margin: 0 0 0 -1px; // override default ul
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc; // Fallback for IE7-8
  border: 1px solid #ccc;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  //.border-radius(6px);
  .box-shadow(0 5px 10px rgba(0,0,0,.2));
  -webkit-background-clip: padding-box;
     -moz-background-clip: padding;
          background-clip: padding-box;

  // Aligns the dropdown menu to right
  &.pull-right {
    right: 0;
    left: auto;
  }


  // Links within the dropdown menu
  > li > a {
    display: block;
    padding: 3px 30px;
    clear: both;
    font-weight: normal;
    white-space: nowrap;
	font-family: @serifFont;
	font-size: @baseFontSize * 3;
  }
}

.btn-group .dropdown-menu {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 338px;
  min-width: 100%;
}

// Hover/Focus state
// -----------
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  text-decoration: none;
  color: @betuSzin;
  background-color: @majdnemFeher;
  outline: 0;
}

// Active state
// ------------
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: @betuSzin;
  text-decoration: none;
  outline: 0;
}

// Disabled state
// --------------
// Gray out text and ensure the hover/focus state remains gray
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: @menuBetuSzin;
}
// Nuke hover/focus effects
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none; // Remove CSS gradient
  cursor: default;
}

// Open state for the dropdown
// ---------------------------
.open {
  // IE7's z-index only goes to the nearest positioned ancestor, which would
  // make the menu appear below buttons that appeared later on the page
  *z-index: 1000;

  & > .dropdown-menu {
    display: block;
  }
}

// Right aligned dropdowns
// ---------------------------
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// ------------------------------------------------------
// Just add .dropup after the standard .dropdown class and you're set, bro.
// TODO: abstract this so that the navbar fixed styles are not placed here?
.dropup,
.navbar-fixed-bottom .dropdown {
  // Reverse the caret
  .caret {
    border-top: 0;
    border-bottom: 4px solid @black;
    content: "";
  }
  // Different positioning for bottom up menu
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 1px;
  }
}

// Sub menus
// ---------------------------
.dropdown-submenu {
  position: relative;
}
// Default dropdowns
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  .border-radius(0 6px 6px 6px);
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

// Dropups
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  .border-radius(5px 5px 5px 0);
}

// Caret to indicate there is a submenu
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: @black;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: @black;
}

// Left aligned submenus
.dropdown-submenu.pull-left {
  // Undo the float
  // Yes, this is awkward since .pull-left adds a float, but it sticks to our conventions elsewhere.
  float: none;

  // Positioning the submenu
  > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    .border-radius(6px 0 6px 6px);
  }
}

// Tweak nav headers
// -----------------
// Increase padding from 15px to 20px on sides
.dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}
